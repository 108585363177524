<template>
  <dialogTable
    title="插入属性"
    btnType="text"
    btnText="插入属性"
    :data="customfieldIdList"
    :option="option"
    @open="onOpen"
    @row-click="rowClick"
    @submit="onSubmit"
  >
    <template #menu="{ row }">
      <color-text-btn>{{ getTitle(row) }}</color-text-btn>
    </template>
  </dialogTable>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      selectData: {},
      selectId: null,
      option: {
        rowKey: 'id',
        reserveSelection: true,
        pageSizes: [10, 20, 50, 100],
        column: [
          {
            label: '属性名称',
            prop: 'fieldName'
          },
          {
            label: '属性code',
            prop: 'variableName'
          }
        ]
      }
    }
  },

  computed: {
    ...mapGetters(['customfieldIdList'])
  },

  methods: {
    onOpen() {
      this.selectId = null
      this.selectData = {}
    },

    rowClick(row, event, column) {
      this.selectData = row
      this.selectId = row.id
    },

    getTitle(data) {
      return data.id == this.selectId ? '已选择' : '未选择'
    },

    async onSubmit(data, done) {
      if (!this.selectId) {
        this.$message.error('请先选择插入的属性')
        return done(false)
      }
      this.$emit('insertHandler', this.selectData)
      done()
    }
  }
}
</script>

<style lang="scss" scoped></style>
